import React, { useState, useEffect } from 'react';
import { Section } from './section';

import './aboutSection.css';
import audeImage from '../images/aude_macaron_cercle350AvecTrou.png';

export const AboutSection = () => {
  const [coeff, setCoeff] = useState(typeof window !== 'undefined' ? Math.min(window.innerWidth / 840, 1) : 0.45);
  const height = 880 * coeff;

  const onResize = () => {
    setCoeff(Math.min(window.innerWidth / 840, 1));
  };

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    window.addEventListener('deviceorientation', onResize, true);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('deviceorientation', onResize, true);
    };
  }, []);

  return (
    <Section title="">
      {/* Paste the inner div of the canva page */}
      {/* set image opacity from 0.66 to 1 in css */}
      {/* comment three first 720px size in css */}
      {/* replace aude image to aude_macaron_cercle350AvecTrou */}
      {/* <div className="ag9i9g">
        <div className="GSyGMg gk5wYw style-ceaeh" id="style-ceaeh">
          <div className="UKMuSA style-oI5Hm" id="style-oI5Hm">
            <div className="onhyOQ style-kFDsk" id="style-kFDsk">
              <div className="twbtjQ">
                <div className="GDnEHQ style-CjEq5" id="style-CjEq5">
                  <div className="o2Yl2g"> */}
      <div
        style={{ transform: `scale(${coeff})`, transformOrigin: 'top left', height: `${height}px` }}
        className="_mXnjA style-7QxJP"
        lang="fr-FR"
        id="style-7QxJP">
        <div id="style-b9mMS" className="style-b9mMS"></div>
        <div className="DF_utQ _0xkaeQ style-jcjiX" id="style-jcjiX">
          <div id="style-O4Daj" className="style-O4Daj">
            <div className="DF_utQ _0xkaeQ style-1U2Jw" id="style-1U2Jw">
              <div className="Zp7NQw">
                <div className="a26Xuw">
                  <div className="PcHy7w">
                    <div className="uk_25A Ty61NA">
                      <div className="Izwocg style-snZOU" id="style-snZOU">
                        {/* <img
                                        src={audeHalo}
                                        alt="Aude Fasani"
                                        title="Aude Fasani"
                                        // className={styles.imageAude}
                                      /> */}
                        <img
                          className="dMHlHA"
                          // crossorigin="anonymous"
                          // src="blob:https://www.canva.com/7468d24a-04b7-435b-aa20-1743e9613ec1"
                          src={audeImage}
                          draggable="false"
                          alt="Aude"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div id="style-wUl7y" className="style-wUl7y"></div>
              </div>
            </div>
            <div className="DF_utQ _0xkaeQ style-p8KdF" id="style-p8KdF">
              <div id="style-JrOOz" className="style-JrOOz">
                <div className="hWv4NA style-fzU4v" id="style-fzU4v">
                  <svg className="_7KaXww">
                    <defs>
                      <clipPath id="__id9">
                        <path d="M500.002000008,250.00300001200003C500.002000008,388.0685522742091 388.0705522822091,500 250.001000004,500C111.92944771779086,500 0,388.06855227420914 0,250.00300001200006C0,111.93044772179088 111.92944771779086,0 250.001000004,0C388.07255229020916,0 500.002000008,111.93044772179088 500.002000008,250.00300001200003Z"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  {/* <div className="bFnJ2A style-AaQTo" id="style-AaQTo">
                                  <div className="_4c2rDg GxUsfw style-kG2YY" id="style-kG2YY">
                                    <div className="qhHTGg style-gW6gs" id="style-gW6gs">
                                      <img
                                        className="_7_i_XA"
                                        crossorigin="anonymous"
                                        // src="https://media.canva.com/v2/image-resize/format:JPG/height:533/quality:92/uri:s3%3A%2F%2Fmedia-private.canva.com%2FbtKcQ%2FMAFO6SbtKcQ%2F1%2Fp.jpg/watermark:F/width:800?csig=AAAAAAAAAAAAAAAAAAAAAFIJXPOZKvC7GmTws8a5xXA2NRiLavoaxnoG2PT_d8el&amp;exp=1731255311&amp;osig=AAAAAAAAAAAAAAAAAAAAAF2u3Dm8vxu0S02mKJ4Y1WbdBLY2UnR-x7ntAH39pQjs&amp;signer=media-rpc&amp;x-canva-quality=screen"
                                        src={audeImage}
                                        draggable="false"
                                      />
                                    </div>
                                  </div>
                                </div> */}
                </div>
                <div className="Pr6LEA"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-imy2Z" id="style-imy2Z">
          <div id="style-cTz7r" className="style-cTz7r">
            <div className="hWv4NA style-SBDRw" id="style-SBDRw">
              <svg className="_7KaXww">
                <defs>
                  <clipPath id="__id10">
                    <path d="M145.71930270389325,0C65.24075406455593,0 0,57.30755202165844 0,128C0,198.69244797834153 65.24075406455589,256 145.71930270389322,256C226.19785134323058,256 291.43860540778644,198.6924479783416 291.4386054077865,128C291.4386054077865,57.30755202165849 226.19785134323064,0 145.71930270389328,0Z"></path>
                  </clipPath>
                </defs>
              </svg>
              <div className="bFnJ2A style-Rl6la" id="style-Rl6la"></div>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-Tox9T" id="style-Tox9T">
          <div id="style-Ndf2Q" className="style-Ndf2Q">
            <div className="hWv4NA style-iY9oq" id="style-iY9oq">
              <svg className="_7KaXww">
                <defs>
                  <clipPath id="__id11">
                    <path d="M128,0C57.30755202165845,0 0,58.21670545206245 0,130.0306510221852C0,201.84459659230797 57.307552021658424,260.06130204437045 128,260.06130204437045C198.69244797834153,260.06130204437045 256,201.84459659230802 256,130.03065102218525C256,58.21670545206249 198.6924479783416,0 128,0Z"></path>
                  </clipPath>
                </defs>
              </svg>
              <div className="bFnJ2A style-xrZIn" id="style-xrZIn"></div>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-Kl1MI" id="style-Kl1MI">
          <div id="style-OlagE" className="style-OlagE">
            <div className="hWv4NA style-iIOlx" id="style-iIOlx">
              <svg className="_7KaXww">
                <defs>
                  <clipPath id="__id12">
                    <path d="M323.8245613778345,0C144.98119450821082,0 0,57.30755202165844 0,128C0,198.69244797834153 144.98119450821073,256 323.82456137783447,256C502.66792824745824,256 647.6491227556689,198.6924479783416 647.649122755669,128C647.649122755669,57.30755202165848 502.66792824745835,0 323.8245613778346,0Z"></path>
                  </clipPath>
                </defs>
              </svg>
              <div className="bFnJ2A style-dWPRV" id="style-dWPRV"></div>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-HL9Kd" id="style-HL9Kd">
          <div id="style-iJ21C" className="style-iJ21C">
            <div className="hWv4NA style-TEBQ4" id="style-TEBQ4">
              <svg className="_7KaXww">
                <defs>
                  <clipPath id="__id13">
                    <path d="M236.73673616838383,0C105.99064705786945,0 0,57.30755202165844 0,128C0,198.6924479783415 105.99064705786938,256 236.7367361683838,256C367.4828252788982,256 473.4734723367676,198.6924479783416 473.47347233676766,128C473.47347233676766,57.30755202165848 367.48282527889836,0 236.73673616838389,0Z"></path>
                  </clipPath>
                </defs>
              </svg>
              <div className="bFnJ2A style-nybwx" id="style-nybwx"></div>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-xfvEp" id="style-xfvEp">
          <div id="style-O4UGM" className="style-O4UGM">
            <div className="hWv4NA style-FlpRo" id="style-FlpRo">
              <svg className="_7KaXww">
                <defs>
                  <clipPath id="__id14">
                    <path d="M279.1005470189107,0C124.95757123093402,0 0,57.30755202165843 0,128C0,198.69244797834153 124.95757123093397,256 279.1005470189106,256C433.24352280688737,256 558.2010940378213,198.69244797834156 558.2010940378213,128C558.2010940378213,57.30755202165848 433.2435228068874,0 279.1005470189108,0Z"></path>
                  </clipPath>
                </defs>
              </svg>
              <div className="bFnJ2A style-NUWII" id="style-NUWII"></div>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-9otWE" id="style-9otWE">
          <div className="aF9o6Q _0yZ6Qg style-yxK9z" id="style-yxK9z">
            <div lang="fr-FR" className="AdBbhQ hGbcYA rLWGlw style-9d3MT" id="style-9d3MT">
              <p className="cgHgbA pYZEjA Xp24Nw PanoWQ style-O7Jnh" id="style-O7Jnh">
                <span className="OYPEnA style-yrzfY" id="style-yrzfY">
                  Moi, c’est Aude FASANI...
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-sQkEB" id="style-sQkEB">
          <div className="aF9o6Q _0yZ6Qg style-MbENB" id="style-MbENB">
            <div lang="fr-FR" className="AdBbhQ hGbcYA style-oJ7oJ" id="style-oJ7oJ">
              <p className="cgHgbA pYZEjA Xp24Nw PanoWQ style-g9Ydo" id="style-g9Ydo">
                <span className="OYPEnA style-vOvn5" id="style-vOvn5">
                  Mon métier?
                </span>
              </p>
              <p className="cgHgbA pYZEjA Xp24Nw style-dYq2E" id="style-dYq2E">
                <span className="OYPEnA style-BClpr" id="style-BClpr">
                  Je suis accompagnante au Bien-Être par le massage, la relaxation et l’expression corporelle.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-rcmqS" id="style-rcmqS">
          <div className="aF9o6Q _0yZ6Qg style-AHWqi" id="style-AHWqi">
            <div lang="fr-FR" className="AdBbhQ hGbcYA rLWGlw style-PSGb8" id="style-PSGb8">
              <p className="cgHgbA pYZEjA Xp24Nw PanoWQ style-4Gs41" id="style-4Gs41">
                <span className="OYPEnA style-P22sF" id="style-P22sF">
                  Mon parcours?
                </span>
                <span className="OYPEnA L7Ipcg style-Ne5fe" id="style-Ne5fe">
                  {' '}
                </span>
              </p>
              <p className="cgHgbA pYZEjA Xp24Nw style-oooYc" id="style-oooYc">
                <span className="OYPEnA style-Rbryn" id="style-Rbryn">
                  Après 20 ans dans la petite enfance, je me
                  <br />
                  suis reconvertie en 2016 dans le domaine du
                  <br />
                  bien-être.
                </span>
              </p>
              <p className="cgHgbA pYZEjA Xp24Nw style-NT91Z" id="style-NT91Z">
                <span className="OYPEnA style-wxAqR" id="style-wxAqR">
                  Formations en massages, diplôme de
                  <br />
                  relaxologue, formation à l’expression
                  <br />
                  corporelle mouvements dansés.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-xDUhk" id="style-xDUhk">
          <div className="aF9o6Q _0yZ6Qg style-A7atZ" id="style-A7atZ">
            <div lang="fr-FR" className="AdBbhQ hGbcYA rLWGlw style-lOfGM" id="style-lOfGM">
              <p className="cgHgbA pYZEjA Xp24Nw PanoWQ style-jSxh8" id="style-jSxh8">
                <span className="OYPEnA style-PoZnP" id="style-PoZnP">
                  Ma vision du métier ?
                </span>
              </p>
              <p className="cgHgbA pYZEjA Xp24Nw style-shs3V" id="style-shs3V">
                <span className="OYPEnA style-4dSoI" id="style-4dSoI">
                  Apporter dans votre vie, un moment
                  <br />
                  de détente rien que pour vous. Vous
                  <br />
                  aider à vous recentrer, vous
                  <br />
                  reconnecter à votre corps, vous
                  <br />
                  accepter tout en respectant votre
                  <br />
                  pudeur, vos craintes, vos maux.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-4IgQU" id="style-4IgQU">
          <div className="aF9o6Q _0yZ6Qg style-AY6Th" id="style-AY6Th">
            <div lang="fr-FR" className="AdBbhQ hGbcYA rLWGlw style-7tXda" id="style-7tXda">
              <p className="cgHgbA pYZEjA Xp24Nw PanoWQ style-79Bw6" id="style-79Bw6">
                <span className="OYPEnA style-flvAU" id="style-flvAU">
                  Le + chez Aude Bien-Être?
                </span>
              </p>
              <p className="cgHgbA pYZEjA Xp24Nw style-oJwCk" id="style-oJwCk">
                <span className="OYPEnA style-ePplM" id="style-ePplM">
                  Votre soin sera personnalisé
                  <br />
                  suite à un échange que nous
                  <br />
                  prendrons le temps d’avoir en
                  <br />
                  début de séance.
                </span>
              </p>
              <p className="cgHgbA pYZEjA Xp24Nw style-rTV3f" id="style-rTV3f">
                <span className="OYPEnA style-hjlQn" id="style-hjlQn">
                  Chaque personne est unique
                  <br />
                  et a donc ses propres besoins.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="DF_utQ _0xkaeQ style-cNOxX" id="style-cNOxX">
          <div className="aF9o6Q _0yZ6Qg style-OUwRj" id="style-OUwRj">
            <div lang="fr-FR" className="AdBbhQ hGbcYA rLWGlw style-CaTc3" id="style-CaTc3">
              <p className="cgHgbA pYZEjA Xp24Nw PanoWQ style-ovhjP" id="style-ovhjP">
                <span className="OYPEnA style-YXXmL" id="style-YXXmL">
                  Le lieu?
                </span>
              </p>
              <p className="cgHgbA pYZEjA Xp24Nw style-eApfo" id="style-eApfo">
                <span className="OYPEnA style-PWQtH" id="style-PWQtH">
                  A mon domicile dans une pièce
                  <br />
                  cocooning avec une table de
                  <br />
                  massage chauffante.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* //           </div> */}
      {/* //           <div className="QhExXw pKfnlA style-zZjoV" id="style-zZjoV"></div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div> */}
    </Section>
  );
};
